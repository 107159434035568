import { getWebSiteSchema } from 'functions/schemaHelpers';
import theme from '../components/theme';
import { Box, CssBaseline } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import dynamic from 'next/dynamic';
import PWA from '../components/pwa';
import reportError from '../components/reportError';
import useJssStyles from 'react-storefront/hooks/useJssStyles';
import useAppStore from 'react-storefront/hooks/useAppStore';
import { appWithTranslation, useTranslation } from 'tools/i18n';
import { ErrorProvider } from '../components/providers/error';
import { SessionProvider } from '../components/providers/session';
import { TrackingProvider } from '../components/providers/tracking';
import { ScrollProvider } from '../components/providers/scroll';
import { ImageGalleryIndexProvider } from '@components/providers/imageGalleryIndex';
import polyfill from '@tools/polyfills';

import Head from 'next/head';
import { JsonLd } from 'react-schemaorg';
import LazyHydrate from 'tools/lazyHydrate';

const Header = dynamic(() => import('../components/header'));
const Footer = dynamic(() => import('../components/footer'));
import Router, { useRouter } from 'next/router';
import PopupWrapper from '../components/popup';
import '../public/css/fonts.css';
import { useEffect } from 'react';
import useApplicationStore from '@services/stores/ApplicationStore';
import init from '@tools/polyfills';
import NotifyToaster from '@components/misc/notifications/NotifyToaster';

const useStyles = makeStyles(
	{
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh',
			backgroundColor: theme.palette.primary.background
		}
	},
	{ name: 'Page' }
);
function MyApp({ Component, pageProps, publicConfig }) {
	// Polyfill execute
	init();
	useJssStyles();
	const classes = useStyles();
	const [appData] = useAppStore(pageProps || {});
	const { t } = useTranslation();
	const appStore = useApplicationStore();


	Router.events.on('routeChangeStart', () => {
		window.__lazyHydrateNavigated = true;
	});

	if (appData) {
		const router = useRouter();
		router.basePath = appData.meta.basePath;
	}

	const cacheable = ['frontend.navigation.page', 'frontend.detail.page']?.includes(pageProps?.pageData?.resourceType);
	const schemaData = {
		searchUrl: t('search-url'),
		cmsPage: pageProps?.pageData?.cmsPage,
		category: pageProps?.pageData?.category,
		name: publicConfig?.name || theme.logo.alt,
		metaTitle: pageProps?.pageData?.category?.metaTitle || theme.logo.alt,
		description:
			pageProps?.pageData?.category?.metaDescription ||
			publicConfig?.description ||
			'Explore a wide range of high-quality furniture for your home.',
		keywords: publicConfig?.keywords || 'furniture, home decor, living room, bedroom, dining',
		url: pageProps?.appData?.meta?.basePath || 'https://newport.se',
		languageCode: publicConfig?.language?.languageCode || 'sv-SE',
	};

	useEffect(() => {
		appStore.methods.warmupStaticProperties().then(r => null);
	}, [])

	useEffect(() => {
		appStore.methods.setSurrogateKeys(pageProps?.cacheSurrogateKeys || []);
	}, [pageProps?.cacheSurrogateKeys]);

	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content={ theme?.palette?.primary?.themeColor || '#000' } />
				<meta name="theme-color" content={ theme?.palette?.primary?.themeColor  || '#000' } media="(prefers-color-scheme: dark)" />
				<meta name="theme-color" content={ theme?.palette?.primary?.themeColor  || '#000' } media="(prefers-color-scheme: light)" />

				<meta name="np-page-type" content={pageProps?.pageData?.resourceType} />
				{cacheable ? <meta name="np-cacheable" /> : null}
				<link rel="manifest" href="/manifest.webmanifest" />
				<link rel="apple-touch-icon" href="/icon-192x192.png" />
			</Head>
			<PWA errorReporter={reportError}>
				<ScrollProvider>
					<ErrorProvider>
						<SessionProvider url="/api/session" config={publicConfig}>
							<TrackingProvider gtmId={publicConfig.gtmId} pageProps={pageProps}>
									<ThemeProvider theme={theme}>
										<ImageGalleryIndexProvider>
											<Box className={classes.wrapper}>
												{pageProps?.pageData?.canonicalPathInfo === '/' &&
													<JsonLd item={getWebSiteSchema(schemaData)} />}
												<CssBaseline />
												<NotifyToaster />
												<Header menu={appData && appData.menu} footer={appData && appData.footer} />
												<Component {...pageProps} appData={appData} />
												<LazyHydrate id="footer" on="visible">
													<Footer
														menu={appData && appData.footer}
														paymentMethods={pageProps?.pageData?.paymentMethods}
													/>
												</LazyHydrate>
												<PopupWrapper />
											</Box>
										</ImageGalleryIndexProvider>
									</ThemeProvider>
							</TrackingProvider>
						</SessionProvider>
					</ErrorProvider>
				</ScrollProvider>
			</PWA>
		</>
	);
}

MyApp.getInitialProps = async function({ Component, ctx }) {
	let pageProps = {};

	if (Component.getInitialProps) {
		pageProps = await Component.getInitialProps(ctx);
	}

	return {
		pageProps,
		publicConfig: ctx.req ? ctx.req.currentDomainConfig.public : window.__NEXT_DATA__.props.publicConfig,
		namespacesRequired: ['general', 'attributes', 'errors', 'product-info']
	};
};

export default appWithTranslation(MyApp);
